// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const id = {
  game_history: `Sejarah Permainan`,
  customer_service: `Pelayanan pelanggan`,
  name: `Nama`,
  time: `Waktu`,
  bet_amount: `Jumlah Taruhan`,
  login: `Gabung`,
  register: `Daftar`,
  captcha: `CAPTCHA`,
  forgot_password: `Tidak ingat kata sandi`,
  minor_note: `Untuk mengunjungi situs ini, pastikan Anda berusia di atas 18 tahun dan setuju`,
  terms_of_service: `Ketentuan Layanan`,
  sign_up: `Mendaftar`,
  username: `Nama belakang`,
  email: `Surel`,
  full_name: `Nama lengkap`,
  confirm_18: `Saya mengonfirmasi bahwa saya berusia 18 tahun dan saya telah membaca`,
  share: `Membagikan`,
  deposit: `Menyetorkan`,
  bonus: `Bonusnya`,
  app: `App`,
  load_more: `Muat lebih banyak`,
  details: `Detail`,
  helps: `BANTUAN`,
  faq: `FAQ`,
  amount: `Jumlah`,
  extra: `Tambahan`,
  deposit_bonus: `Bonus Setoran`,
  do_not_participate: `Jangan berpartisipasi dalam promosi`,
  payment_problem: `Masalah Pembayaran`,
  click_for_help: `Klik untuk bantuan`,
  deposit_notes: `Catatan Setoran`,
  available_balance: `Saldo Tersedia`,
  withdraw: `Menarik`,
  withdrawal_notes: `Catatan Penarikan`,
  withdrawal_note1: `Jumlah penarikan saat ini`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Biaya penarikan:`,
  min: ``,
  max: `Max:`,
  notifications: `Pemberitahuan`,
  invalid_x: `{{x}} tidak valid`,
  captcha_not_match: `Captcha Tidak Cocok`,
  invalid_x_must_be_atleast_y_characters: `{{x}} tidak valid, minimal harus {{y}} karakter`,
  x_is_required: `{{x}} diperlukan`,
  phone_number: 'Nomor telepon',
  phone_num_x_max: `Nomor Telepon maksimal {{x}} digit`,
  email_sent: `Email terkirim, silakan periksa email Anda.`,
  enter_valid_email: `Tolong masukkan email yang benar.`,
  confirm: `Mengonfirmasi`,
  password_atleast_6_characters: `Kata sandi harus minimal 6 karakter.`,
  password_reset_success: `Penyetelan ulang kata sandi berhasil!.`,
  new_password: `Kata Sandi Baru`,
  bank: 'Bank',
  bank_transfer: `Transfer Bank`,
  bank_name: `Nama Bank`,
  account_name: `Nama akun`,
  account_number: `Nomor akun`,
  drop_or_select: `Jatuhkan atau Pilih file`,
  drop_files_here: `Letakkan file di sini`,
  transfer_to: `Transfer Ke`,
  upload_receipt: `Unggah Tanda Terima`,
  select_bonus: `Pilih Bonus`,
  no_promotion: `Tidak Ada Promosi`,
  please_upload_receipt: `Silakan unggah tanda terima`,
  bank_account: `Akun bank`,
  add_account: `Menambahkan akun`,
  account_holder_name: `nama pemilik akun`,
  name_of_the_account_holder: `Nama Pemilik Rekening`,
  fill_in_bank_account: `Isi Rekening Bank`,
  withdraw_information: `Tarik Informasi`,
  note: `Catatan`,
  amount_withdrawn: `Jumlah yang ditarik`,
  amount_no_exceed_balance: `Jumlah Penarikan tidak boleh melebihi saldo yang tersedia`,
  minimum_withdraw_amount_is_x: `Jumlah Penarikan Minimum adalah {{x}}`,
  transaction_history: `sejarah transaksi`,
  date_from: `Tanggal Dari`,
  date_to: `Tanggal Ke`,
  account_name_must_be_4: `Panjang Nama Akun harus lebih dari 4`,
  account_number_must_be_x: `Nomor rekening harus 10-14 panjangnya`,
  minimum_length_is_x: `Panjang minimumnya adalah {{x}}`,
  maximum_length_is_x: `Panjang maksimum adalah {{x}}`,
  payment_method: `Cara Pembayaran`,
  mode: `Mode`,
  no_bank_account: `Anda tidak memiliki akun untuk menarik dana, Tambahkan sekarang`,
  please_select_bank: `Silakan pilih Rekening Bank`,

  bet_history: `Sejarah Taruhan`,
  game_provider: `Penyedia Permainan`,
  bet_count: `Jumlah Taruhan`,
  win_loss: `Menang/Kalah`,
  rebate: `Potongan harga`,
  turnover: `Pergantian`,
  game_category: `Kategori Permainan`,

  you_have_x_unread_messages: `Anda memiliki {{x}} pesan yang belum dibaca`,
  deposit_amount_between_x_and_y: `Jumlah deposit harus antara {{x}} dan {{y}}`,
  minimum_amount_is_x_y: `Jumlah minimumnya adalah {{x}}{{y}}`,

  // all
  all: `Semua`,
  title: `Judul`,
  date: `Tanggal`,
  type: `Jenis`,
  status: `Status`,
  remark: `Komentar`,
  category: `Kategori`,
  please_select_x: `Silakan pilih {{x}}`,
  save: `Menyimpan`,

  change_password: `Ganti kata sandi`,
  current_password: `kata sandi saat ini`,
  confirm_password: `Konfirmasi password baru`,
  submit: `Kirim`,
  password_changed: `Kata Sandi Berubah`,
  x_must_be_y_characters: `{{x}} minimal harus {{y}} karakter`,
  reset_password: `Atur Ulang Kata Sandi`,
  clear: `Jernih`,
  search: `Mencari`,
  new: `Baru`,
  logout: `Keluar`,
  choose_date: `Pilih Tanggal`,
  select_both_date: `Pilih Tanggal Dari dan Ke`,
  from: `Dari`,
  to: `Ke`,
  apply: `Menerapkan`,
  promotions: `Promosi`,
  learn_more: `BELAJARLAH LAGI`,
  password_doesnt_match: `Kata sandi tidak cocok`,
  cancel: `Membatalkan`,
  confirm_to_pay: `Konfirmasi Untuk Membayar`,
  deposit_amount: `Jumlah Setoran`,
  loading_please_wait: `Memuat, Harap Tunggu`,
  account: `Akun`,
  profile: `Profil`,
  bank_list: `Daftar Bank`,
  in_maintenance: `Dalam Pemeliharaan`,
  birthday: `Hari ulang tahun`,
  birthday_bonus: `Masukkan tanggal lahir Anda untuk mendapatkan bonus! 🎉🎁`,
  referral_code: `Kode Referensi`,
  referral: `Rujukan`,
  my_referral: `Referensi saya`,
  my_claim: `Klaim saya`,
  claim_profit: `KLAIM KEUNTUNGAN`,
  available_profit: `Keuntungan yang Tersedia`,
  claimed_profit: `Keuntungan yang Diklaim`,
  all_time_profit: `Keuntungan Sepanjang Masa`,
  referral_note_on_x: `Hasilkan keuntungan setiap kali teman Anda bermain di {{x}}`,
  tier: `Tingkat`,
  commission: `Komisi`,
  referred_member: `Anggota yang Dirujuk`,
  profit_earned: `Keuntungan yang Diperoleh`,
  account_information: `Informasi Akun`,
  invite_friends: `Mengundang teman-teman`,
  how_it_work: `Bagaimana cara kerjanya?`,
  invite_note: `Dapatkan bonus referensi eksklusif ketika teman Anda mendaftar menggunakan kode referensi Anda!`,
  share_via: `Bagikan melalui`,
  referral_link: `Tautan Referensi`,
  total_profit: `Total keuntungan`,
  copied: `Disalin`,
  nothing_to_claim: `Tidak ada yang perlu diklaim`,
  claim_history: `Riwayat Klaim`,
  downline: `Downline saya`,
  last_x_digit_bank_ref_number: `{{x}} digit terakhir nomor Bank Ref`,
  bank_ref_number_validation_x: `Nomor Referensi Bank wajib diisi dan panjangnya harus {{x}} karakter`,
  sort: 'Menyortir',

  refer_and_reward: `Referensi & Hadiah`,
  linked_successfully_x: `Berhasil ditautkan dengan {{x}}! Silakan daftarkan akun Anda untuk pertama kalinya`,
  more_x_of_y: `Lainnya {{x}}/{{y}}`,
  default: `Bawaan`,
  promotion: `Promosi`,
  daily_mission: 'Misi Harian',
  agent: 'Agen',
  contact_us: 'Hubungi kami',
  download: 'Unduh',
  cash_claim: 'Klaim Tunai',
  demo: 'Demo',
  favorites: 'Favorit',
  popular: 'Populer',
  claim_bonus: `Klaim Bonus`,
  insufficient_balance: `Saldo Tidak Cukup`,
  currently_displaying: 'Saat ini menampilkan {{x}} {{y}} permainan dari {{z}}',
  all_x: 'Semua {{x}}',
  no_results: 'Tidak ada hasil yang ditemukan',
  search_games: 'Permainan Pencarian',
  please_scan_using_your: `Silakan memindai menggunakan Anda`,
  vip_level: `Tingkat VIP`,
  bonus_direct: `BONUS LANGSUNG`,
  rebate_level: `Tingkat Rabat`,
  vip_header: `Semua taruhan yang dipasang pada Mesin Slot, Permainan Papan dan Kartu, Memancing, Video, Olahraga dan Permainan Lotere di S24 akan diakumulasikan secara permanen. Taruhan Sah = Penghasilan Permanen dan Uang Rejeki!`,
  vip_example: `
  Example 1: Members who reach 1 Million in accumulated deposits, reach VIP Level 2, receive a bonus of R$210,and Rebate 3.50%, When they reach 6 Million in accumulated deposit, they reach VIP Level 3 and receive a bonus of R$800, with a Rebate 4%`,
  activity_rules: `III. Activity Rules:`,
  activity_rules_1: `1. It is necessary for all bonuses to have x3 Winover to request the Withdrawal`,
  activity_rules_2: `2. Please claim the Rebate Bonus within the deadline, if you happen to be late, it will be considered abandonment;`,
  activity_rules_3: `3. Members' participation in activities is automatically counted by the system, if there is any objection, the result of S24's investigation will prevail;`,
  activity_rules_4: `4. If you forget your member Account/Password, please contact "24-hour Online Customer Service" to help you recover your account information;`,
  activity_rules_5: `5. S24 reserves the right to change, stop or cancel this promotion at any time.`,
  activity_rules_6: `6. Do not save free game , Buy free game`,
  activity_rules_7: `7. Can't combine with any bonus`,
  activity_rules_8: `8. Maximum Withdraw per Deposit is R$100,000`,
  member: `Anggota`,
  return_home: `Kembali ke rumah`,
  more: `Lagi`,
  earn: `Menghasilkan`,
  deposit_method: `Metode Setoran`,
  next: `Berikutnya`,
  my_account: `Akun saya`,
  level: `Tingkat`,
  providers: `Penyedia`,
  home: `Rumah`,
  member_center: `Pusat Anggota`,
  adjustment: `Pengaturan`,
  no_balance: `Tidak Ada Saldo`,
  withdraw_amount: `Jumlah Penarikan`,
  lucky_draw: `Undian`,
  services: `Jasa`,
  fast_transaction: `Transaksi Cepat`,
  fast_transaction_desc: `Kami menyediakan berbagai saluran penyetoran, dompet otomatis bebas transfer, proses penarikan yang efisien, dan kemampuan untuk mencapai waktu penyetoran, penarikan, dan transfer yang sangat cepat. Teknologi optimalisasi jaringan eksklusif mengurangi latensi jaringan untuk memberikan pengalaman bermain game yang superior.`,
  safety_guaranteed: `Keamanan Terjamin`,
  safety_guaranteed_desc: `Kami menggunakan detektor keamanan internet yang terkenal secara internasional untuk pengujian yang ketat, serta teknologi enkripsi 128-Bit, untuk memastikan privasi & keamanan data Anda. Informasi pribadi Anda tidak akan pernah diungkapkan atau dijual kepada pihak ketiga. S24, platform hiburan online berlisensi`,
  game_options: `Opsi Permainan`,
  game_options_desc: `Kami menghadirkan ribuan acara olahraga menarik seperti sepak bola, bola basket, e-sport, tenis, snooker, dan banyak lagi, semuanya dalam waktu nyata! Ada juga permainan lain yang bisa dipilih, seperti live casino, lotere, poker, memancing, dan slot.`,
  multi_platform: `Multi-Platform`,
  multi_platform_desc: `Dengan teknologi terdepan, S24 mengembangkan serangkaian aplikasi yang mandiri dan lengkap. Anda sekarang dapat menikmati aplikasi terintegrasi web, H5, iOS dan Android, memungkinkan Anda menemukan hiburan dan bertaruh kapan pun dan di mana pun pada apa pun. Penyedia layanan pelanggan 24/7, layanan dan bantuan yang penuh perhatian dan efisien.`,
  hot_games: `Permainan Panas`,
  sports: `Olahraga`,
  casino: `Kasino`,
  slots: `Slot`,
  fishing: `Penangkapan ikan`,
  cards: `Kartu-kartu`,
  games: `Permainan`,
  terms_n_conditions: `SYARAT DAN KETENTUAN`,
  claim_now: `KLAIM SEKARANG`,
  the_activity_x_in: `Aktivitas {{x}} Masuk`,
  starts: `Dimulai`,
  ends: `Berakhir`,
  list_of_winners: `DAFTAR PEMENANG`,
  days: `Hari`,
  hours: `Jam`,
  minutes: `Menit`,
  seconds: `Detik`,
  please_login: `Silahkan masuk`,
  promo_concluded: `Promo Selesai`,
  bonus_claimed: `Bonus Diklaim`,
  can_only_claim: `Hanya dapat mengklaim bonus antara {{x}} hingga {{y}} Waktu Malaysia`,
  total_amount_received: `Jumlah Total Diterima`,
  deposit_fee: `Biaya Setoran`,
  get: 'dapat',
  before_that: 'Sebelum itu',
  deposit_from_bank: 'Setoran Dari Bank',
};

export default id;
