import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Divider,
  Grid,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemButton,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// utils
import { fToNow } from '../../utils/formatTime';
// _mock_
import { _notifications } from '../../_mock';
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { useDispatch, useSelector } from '../../redux/store';
import { getInbox, getUnreadInboxCount, setUnreadInboxCount } from '../../redux/slices/lookup';
import useInterval from '../../hooks/useInterval';
import useAuth from '../../hooks/useAuth';
import LinearLoader from '../../components/LinearLoader';
import useLocales from '../../hooks/useLocales';
import Image from '../../components/Image';

const BottomNav = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  flexDirection: 'column',
}));

const BottomNavIcon = styled(Image)(({ theme }) => ({
  position: 'relative',
  width: '.62rem',
  height: '.62rem',
  margin: '0 auto',
}));

const BottomNavText = styled(Typography)(({ theme }) => ({
  fontSize: '.2rem',
  color: '#8d99ae',
  textAlign: 'center',
  lineHeight: 1,
}));

// ----------------------------------------------------------------------

export default function NotificationPopover() {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { unreadInboxCount, inbox, memberBalance } = useSelector((x) => x.lookup);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    if (!isAuthenticated) {
      navigate('/auth/login');
      return;
    }

    if (open) {
      handleClose();
    } else {
      setIsLoading(true);
      getInbox().finally(() => {
        setIsLoading(false);
        dispatch(setUnreadInboxCount(0));
      });
    }
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const unreadMessages = useMemo(() => inbox.filter((m) => !m.read), [inbox]);
  const readMessages = useMemo(() => inbox.filter((m) => m.read), [inbox]);

  useInterval(getUnreadInboxCount, 15000);

  return (
    <>
      <BottomNav onClick={handleOpen} item xs={12 / 5}>
        <BottomNavIcon src={require('../../assets/menu/bottom/footer-message-inactive.png')} />
        <BottomNavText>{translate('notifications')}</BottomNavText>
      </BottomNav>
      {/* <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 35, height: 35 }}>
        <Badge badgeContent={unreadInboxCount || 0} color="error">
          
          <Iconify icon="noto-v1:envelope" width={35} height={35} />
        </Badge>
      </IconButtonAnimate> */}

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75, background: '#f9f9f9' }}
        arrow={<></>}
      >
        {isLoading ? (
          <Box sx={{ p: 1 }}>
            <LinearLoader />
          </Box>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ fontSize: '.42rem', color: '#181644' }}>{translate('notifications')}</Typography>
                <Typography sx={{ color: '#181644', fontSize: '.26rem', opacity: 0.5 }}>
                  {translate('you_have_x_unread_messages', { x: unreadInboxCount })}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
              {unreadMessages?.length ? (
                <List
                  disablePadding
                  subheader={
                    <ListSubheader
                      disableSticky
                      sx={{ py: 1, px: 2.5, typography: 'overline', fontSize: '.26rem', color: '#181644' }}
                    >
                      {translate('new')}
                    </ListSubheader>
                  }
                >
                  {unreadMessages.map((message) => (
                    <NotificationItem
                      key={message.id}
                      notification={message}
                      currencyCode={memberBalance?.currencyCode}
                    />
                  ))}
                </List>
              ) : (
                ''
              )}

              {readMessages?.length ? (
                <List
                  disablePadding
                  subheader={
                    unreadMessages?.length ? (
                      <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                        {translate('before_that')}
                      </ListSubheader>
                    ) : null
                  }
                >
                  {readMessages.map((message) => (
                    <NotificationItem
                      key={message.id}
                      notification={message}
                      currencyCode={memberBalance?.currencyCode}
                    />
                  ))}
                </List>
              ) : (
                ''
              )}
            </Scrollbar>
          </>
        )}
        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    created: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    read: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    activity_type: PropTypes.string,
    // avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, currencyCode }) {
  const { avatar, title } = renderContent(notification, currencyCode);

  const { currentLang } = useLocales();

  return (
    <ListItemButton
      sx={{
        fontSize: '.26rem',
        color: '#333',
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.read && {
          bgcolor: 'beige',
        }),
        bgcolor: 'beige',
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        sx={{ '& h6': { fontSize: '.26rem' } }}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              // color: 'text.disabled',
              fontSize: '.2rem',
              color: '#333',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.created, currentLang?.value || 'en')}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function getTitle(title, currencyCode) {
  const firstSpaceIndex = title.indexOf(' ');
  const secondSpaceIndex = title.indexOf(' ', firstSpaceIndex + 1);

  if (firstSpaceIndex !== -1) {
    const extractedText = title.substring(firstSpaceIndex + 1, secondSpaceIndex);
    return `${currencyCode} ${parseFloat(extractedText) * 1000} ${title.substring(secondSpaceIndex + 1)}`;
  }
  return '';
}

function renderContent(notification, currencyCode) {
  const _title = notification?.title;
  // if (
  //   (currencyCode?.toUpperCase() === 'MMK' || currencyCode?.toUpperCase() === 'VND') &&
  //   notification?.activity_type === 'deposit'
  // ) {
  //   _title = getTitle(notification?.title, currencyCode);
  // }

  const title = (
    <Typography variant="subtitle2">
      {_title}
      {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography> */}
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api-dev.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
