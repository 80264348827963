// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game_history: `Game History`,
  customer_service: `Customer Service`,
  name: `Name`,
  time: `Time`,
  bet_amount: `Bet Amount`,
  login: `Login`,
  register: `Register`,
  captcha: `CAPTCHA`,
  forgot_password: `Forgot Password`,
  minor_note: `To visit this site, make sure you are over 18 and agree`,
  terms_of_service: `Terms of Service`,
  sign_up: `Sign Up`,
  username: `Username`,
  email: `Email`,
  full_name: `Full Name`,
  confirm_18: `I confirm that I am 18 years old and I have read the`,
  share: `Share`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Load More`,
  details: `Details`,
  helps: `HELPS`,
  faq: `FAQ`,
  amount: `Amount`,
  extra: `Extra`,
  deposit_bonus: `Deposit Bonus`,
  do_not_participate: `Do not participate in promotions`,
  payment_problem: `Payment Problem`,
  click_for_help: `Click for help`,
  deposit_notes: `Deposit Notes`,
  available_balance: `Available Balance`,
  withdraw: `Withdraw`,
  withdrawal_notes: `Withdrawal Notes`,
  withdrawal_note1: `Number of withdrawals in current`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Withdrawal fee:`,
  min: `Min:`,
  max: `Max:`,
  notifications: `Notifications`,
  invalid_x: `Invalid {{x}}`,
  captcha_not_match: `Captcha Does Not Match`,
  invalid_x_must_be_atleast_y_characters: `Invalid {{x}}, must be atleast {{y}} characters`,
  x_is_required: `{{x}} is required`,
  phone_number: 'Phone Number',
  phone_num_x_max: `Phone Number maximum {{x}} digits`,
  email_sent: `Email sent, please check your email.`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `Confirm`,
  password_atleast_6_characters: `Password must be atleast 6 characters.`,
  password_reset_success: `Password reset success!.`,
  new_password: `New Password`,
  bank: 'Bank',
  bank_transfer: `Bank Transfer`,
  bank_name: `Bank Name`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  drop_or_select: `Drop or Select file`,
  drop_files_here: `Drop files here`,
  transfer_to: `Transfer To`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,
  please_upload_receipt: `Please upload receipt`,
  bank_account: `Bank Account`,
  add_account: `Add Account`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the Account Holder`,
  fill_in_bank_account: `Fill in Bank Account`,
  withdraw_information: `Withdraw Information`,
  note: `Note`,
  amount_withdrawn: `Amount withdrawn`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw amount is {{x}}`,
  transaction_history: `Transaction History`,
  date_from: `Date From`,
  date_to: `Date To`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  account_number_must_be_x: `Account number must be 10-14 in length`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  payment_method: `Payment Method`,
  mode: `Mode`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  please_select_bank: `Please select a Bank Account`,

  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  you_have_x_unread_messages: `You have {{x}} unread messages`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}}{{y}}`,

  // all
  all: `All`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  category: `Category`,
  please_select_x: `Please select {{x}}`,
  save: `Save`,

  change_password: `Change Password`,
  current_password: `Current Password`,
  confirm_password: `Confirm New Password`,
  submit: `Submit`,
  password_changed: `Password Changed`,
  x_must_be_y_characters: `{{x}} must be at least {{y}} characters`,
  reset_password: `Reset Password`,
  clear: `Clear`,
  search: `Search`,
  new: `New`,
  logout: `Logout`,
  choose_date: `Choose Date`,
  select_both_date: `Select both Date From and To`,
  from: `From`,
  to: `To`,
  apply: `Apply`,
  promotions: `Promotions`,
  learn_more: `LEARN MORE`,
  password_doesnt_match: `Password doesn't match`,
  cancel: `Cancel`,
  confirm_to_pay: `Confirm To Pay`,
  deposit_amount: `Deposit Amount`,
  loading_please_wait: `Loading, Please Wait`,
  account: `Account`,
  profile: `Profile`,
  bank_list: `Bank List`,
  in_maintenance: `In Maintenance`,
  birthday: `Birthday`,
  birthday_bonus: `Enter your birthday for a bonus! 🎉🎁`,
  referral_code: `Referral Code`,
  referral: `Referral`,
  my_referral: `My Referral`,
  my_claim: `My Claim`,
  claim_profit: `CLAIM PROFIT`,
  available_profit: `Available Profit`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `All Time Profit`,
  referral_note_on_x: `Earn profit every time your friends playing on {{x}}`,
  tier: `Tier`,
  commission: `Commission`,
  referred_member: `Referred Member`,
  profit_earned: `Profit Earned`,
  account_information: `Account Information`,
  invite_friends: `Invite Friends`,
  how_it_work: `How it work?`,
  invite_note: `Get exclusive referral bonuses when your friend signs up using your referral code!`,
  share_via: `Share via`,
  referral_link: `Referral Link`,
  total_profit: `Total Profit`,
  copied: `Copied`,
  nothing_to_claim: `Nothing to claim`,
  claim_history: `Claim History`,
  downline: `My Downline`,
  last_x_digit_bank_ref_number: `Last {{x}} digit of Bank Ref number`,
  bank_ref_number_validation_x: `Bank Ref Number is required and must be {{x}} character length`,
  sort: 'Sort',

  refer_and_reward: `Refer & Reward`,
  linked_successfully_x: `Linked successfully with {{x}}! Please register your account for the first time`,
  more_x_of_y: `More {{x}}/{{y}}`,
  default: `Default`,
  promotion: `Promotion`,
  daily_mission: 'Daily Mission',
  agent: 'Agent',
  contact_us: 'Contact Us',
  download: 'Download',
  cash_claim: 'Cash Claim',
  demo: 'Demo',
  favorites: 'Favorites',
  popular: 'Popular',
  claim_bonus: `Claim Bonus`,
  insufficient_balance: `Insufficient Balance`,
  currently_displaying: 'Currently displaying {{x}} {{y}} games out of {{z}}',
  all_x: 'All {{x}}',
  no_results: 'No Results Found',
  search_games: 'Search Games',
  please_scan_using_your: `Please scan using your`,
  vip_level: `VIP Level`,
  bonus_direct: `BONUS DIRECT`,
  rebate_level: `Rebate Level`,
  vip_header: `All bets placed on Slot Machines, Board and Card Games, Fishing, Videos, Sports and Lottery Games at S24 will be accumulated permanently. Valid bets = Permanent Income and Money Fortune!`,
  vip_example: `
  Example 1: Members who reach 1 Million in accumulated deposits, reach VIP Level 2, receive a bonus of R$210,and Rebate 3.50%, When they reach 6 Million in accumulated deposit, they reach VIP Level 3 and receive a bonus of R$800, with a Rebate 4%`,
  activity_rules: `III. Activity Rules:`,
  activity_rules_1: `1. It is necessary for all bonuses to have x3 Winover to request the Withdrawal`,
  activity_rules_2: `2. Please claim the Rebate Bonus within the deadline, if you happen to be late, it will be considered abandonment;`,
  activity_rules_3: `3. Members' participation in activities is automatically counted by the system, if there is any objection, the result of S24's investigation will prevail;`,
  activity_rules_4: `4. If you forget your member Account/Password, please contact "24-hour Online Customer Service" to help you recover your account information;`,
  activity_rules_5: `5. S24 reserves the right to change, stop or cancel this promotion at any time.`,
  activity_rules_6: `6. Do not save free game , Buy free game`,
  activity_rules_7: `7. Can't combine with any bonus`,
  activity_rules_8: `8. Maximum Withdraw per Deposit is R$100,000`,
  member: `Member`,
  return_home: `Return Home`,
  more: `More`,
  earn: `Earn`,
  deposit_method: `Deposit Method`,
  next: `Next`,
  my_account: `My Account`,
  level: `Level`,
  providers: `Providers`,
  home: `Home`,
  member_center: `Member Center`,
  adjustment: `Adjustment`,
  no_balance: `No Balance`,
  withdraw_amount: `Withdraw Amount`,
  lucky_draw: `Lucky Draw`,
  services: `Services`,
  fast_transaction: `Fast Transaction`,
  fast_transaction_desc: `We provide multiple deposit channels, an automatic transfer-free wallet, a streamlined widhtdrawal process, and the ability to achieve extremely fast deposit, withdrawal, and transfer times. Exclusive network optimization technology reduces network latency to provide a superior gaming experience.`,
  safety_guaranteed: `Safety Guaranteed`,
  safety_guaranteed_desc: `We use internationally renowned internet security detectors for rigorous testing, as well as 128-Bit enryption technology, to ensure the privacy & security of your data. Your personal information will never be disclosed or sold to a third party. S24, a licensed online entertainment platform`,
  game_options: `Game Options`,
  game_options_desc: `We bring your thousands of exciting sports events such as football, basketball, e-sport, tennis, snooker, and many more, all in real time! There are also other games to choose from, such as live casino, lottery, poker, fishing, and slots.`,
  multi_platform: `Multi-Platform`,
  multi_platform_desc: `With leading technology, S24 developed an independent and complete set of applications. You can now enjoy the web, H5, iOS and Android integrated APP, allowig you to find entertainment and bet whenever and wherever on whatever. 24/7 customer service providers attentive and efficient service and help.`,
  hot_games: `Hot Games`,
  sports: `Sports`,
  casino: `Casino`,
  slots: `Slots`,
  fishing: `Fishing`,
  cards: `Cards`,
  games: `Games`,
  terms_n_conditions: `TERMS AND CONDITION`,
  claim_now: `CLAIM NOW`,
  the_activity_x_in: `The Activity {{x}} In`,
  starts: `Starts`,
  ends: `Ends`,
  list_of_winners: `LIST OF WINNERS`,
  days: `Days`,
  hours: `Hours`,
  minutes: `Minutes`,
  seconds: `Seconds`,
  please_login: `Please Login`,
  promo_concluded: `Promo Concluded`,
  bonus_claimed: `Bonus Claimed`,
  can_only_claim: `Can only claim bonus between {{x}} to {{y}} Malaysia Time`,
  total_amount_received: `Total Amount Received`,
  deposit_fee: `Deposit Fee`,
  get: 'Get',
  before_that: 'Before That',
  deposit_from_bank: 'Deposit From Bank'
};

export default en;
